import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import styles from './LocationTab.module.scss'
import { Hotel } from 'src/core/Hotel/domain/Hotel.model'
import { Image } from 'src/ui/components/atoms/Image'
import MapMarker from 'src/ui/components/atoms/Icon/_icons/general/MapMaker'
import { Icon } from 'src/ui/components/atoms/Icon'
import { ButtonLink } from 'src/ui/components/atoms/ButtonLink'
import ChevronIcon from 'src/ui/components/atoms/Icon/_icons/navigation/ChevronIcon'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'
import { createRoot } from 'react-dom/client'
import { Text } from 'src/ui/components'
import { stylesArray } from './LocationTabStyles'
import {
  isDefined,
  isEmpty,
  isNull,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

interface Props {
  hotel: Hotel
}

export const LocationTab: FC<Props> = ({ hotel }) => {
  const { trans } = useTrans(['common'])
  const [hasToShowStaticMapImage, setHasToShowStaticMapImage] = useState(true)
  const [arePoisOpen, setArePoisOpen] = useState(false)
  const mapRef = useRef<HTMLDivElement>(null)

  const hasPois = isDefined(hotel.pois) && !isEmpty(hotel.pois)

  const initMap = useCallback(async () => {
    if (isNull(mapRef.current)) {
      return
    }

    const { Map }: google.maps.MapsLibrary =
      await window.google.maps.importLibrary('maps')
    const { AdvancedMarkerElement }: google.maps.MarkerLibrary =
      await window.google.maps.importLibrary('marker')

    const map = new Map(mapRef.current, {
      zoom: hotel.map.zoom,
      center: { lat: hotel.map.latitude, lng: hotel.map.longitude },
      mapId: 'DEMO_MAP_ID',
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.INLINE_START_BLOCK_END,
      },
    })

    const styledMapType = new google.maps.StyledMapType(stylesArray, {
      name: 'Styled Map',
    })
    map.mapTypes.set('styled_map', styledMapType)
    map.setMapTypeId('styled_map')

    const marker = document.createElement('div')
    const root = createRoot(marker)
    root.render(
      <Icon icon={MapMarker} size="4xl" color="button-secondary-enabled" />,
    )
    new AdvancedMarkerElement({
      map: map,
      position: { lat: hotel.map.latitude, lng: hotel.map.longitude },
      content: marker,
    })
  }, [mapRef, hotel.map])

  useEffect(() => {
    if (hasToShowStaticMapImage || isNull(mapRef.current)) {
      return
    }

    initMap()
  }, [mapRef, hasToShowStaticMapImage, initMap])

  return (
    <div className={styles.container}>
      {hasToShowStaticMapImage ? (
        <div className={styles.mapImageContainer}>
          <Image
            className={styles.mapImage}
            src={hotel.map.staticMapImage}
            alt={trans('aside-modal-tab_location_map-alt')}
            width={1920}
            height={756}
            onClick={() => setHasToShowStaticMapImage(false)}
            unoptimized={true}
          />
        </div>
      ) : (
        <div
          ref={mapRef}
          aria-label={trans('aside-modal-tab_location_map-alt')}
          className={styles.map}
        />
      )}

      <div className={styles.poiSlider}>
        {hasPois && (
          <ButtonLink
            className={styles.showMoreIcon}
            aria-label={trans(
              arePoisOpen ? 'drop-down_show-less' : 'drop-down_show-more',
            )}
            onClick={event => {
              event.preventDefault()
              setArePoisOpen(!arePoisOpen)
            }}
          >
            <Icon
              icon={ChevronIcon}
              size="2xl"
              color="icon-dark"
              rotate={arePoisOpen ? 90 : 270}
            />
          </ButtonLink>
        )}
        <Flex direction="column" gap="xs">
          <Text fontStyle="l-500">
            {trans('aside-modal-tab_location_slider-title')}
          </Text>
          <Text fontStyle="m-500">{hotel.completeAddress}</Text>
        </Flex>
        {arePoisOpen && hasPois && (
          <Flex direction="column" alignItems="stretch" gap="xs">
            {hotel.pois.map((poi, index) => {
              return (
                <Flex justifyContent="space-between" key={index}>
                  <Text fontStyle="m-300">{poi.title}</Text>
                  <Text fontStyle="m-300">{poi.distance}</Text>
                </Flex>
              )
            })}
          </Flex>
        )}
      </div>
    </div>
  )
}
