import { FC } from 'react'
import { SwiperClass } from 'swiper/react'
import { Icon } from 'src/ui/components/atoms/Icon'
import ArrowIcon from 'src/ui/components/atoms/Icon/_icons/navigation/ArrowIcon'
import styles from './NavigationButtons.module.scss'
import { classNames } from 'src/ui/utils/classnames'

interface Props {
  swiperRef: SwiperClass
  isRTL: boolean
  isBeginning: boolean
}

export const NavigationPreviousButton: FC<Props> = ({
  swiperRef,
  isRTL,
  isBeginning,
}) => {
  return (
    <div
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        swiperRef.slidePrev()
      }}
      className={classNames(
        styles.navigation,
        isBeginning && styles.disableArrow,
      )}
    >
      <Icon
        size="xl"
        icon={ArrowIcon}
        color={
          isBeginning
            ? 'interactive-primary-disabled'
            : 'interactive-primary-enabled'
        }
        rotate={isRTL ? -180 : 0}
      />
    </div>
  )
}
