import { useState } from 'react'
import styles from './InformationTab.module.scss'
import { Hotel } from 'src/core/Hotel/domain/Hotel.model'
import {
  isDefined,
  isEmpty,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Carousel, Divider, IconText, Link, Text } from 'src/ui/components'
import { ButtonLink } from 'src/ui/components/atoms/ButtonLink'
import { Image } from 'src/ui/components/atoms/Image'
import { Icon } from 'src/ui/components/atoms/Icon'
import { getHotelImages, getRoomImageLoader } from 'src/ui/utils/images'
import { useTrans } from 'src/ui/hooks/useTrans'
import { useCallCenter } from 'src/ui/hooks/useCallCenter'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Media } from 'src/ui/styles/objects/Media'
import { Translate } from 'src/ui/i18n/Translate'
import ChevronIcon from 'src/ui/components/atoms/Icon/_icons/navigation/ChevronIcon'
import PhoneIcon from 'src/ui/components/atoms/Icon/_icons/interaction/PhoneIcon'
import SuccessIcon from 'src/ui/components/atoms/Icon/_icons/system/alert/SuccessIcon'
import { AwardsAndCertificates } from './AwardsAndCertificates'
import { useHotelCheckInCheckoutTime } from 'src/ui/hooks/queries/useHotelCheckInCheckoutTime'
import { useHotelPromotions } from 'src/ui/hooks/queries/useHotelPromotions'
import { container } from 'src/core/Shared/_di'

interface Props {
  hotel: Hotel
}

const MAX_PROMOTIONS_UNTIL_SHOW_MORE = 3

export const InformationTab = ({ hotel }: Props) => {
  const { trans } = useTrans(['common', 'new-reservation'])
  const [hasToShowAllPromotions, setHasToShowAllPromotions] = useState(false)
  const { callCenterCountryCode, callCenterPhone, callCenterFreePhone } =
    useCallCenter()
  const { hotelCheckInAndCheckOutTime } = useHotelCheckInCheckoutTime()
  const { hotelPromotions } = useHotelPromotions()

  if (isEmpty(hotel.hotelImages)) {
    container
      .resolve('logger')
      .warning(`The hotel ${hotel.id} has no carousel images`)
  }

  const { checkInTime, checkOutTime } = hotelCheckInAndCheckOutTime ?? {}

  const hasHighlightTag =
    isDefined(hotel.highlightTag) && !isEmpty(hotel.highlightTag)
  const hasHighlights =
    isDefined(hotel.highlights) && !isEmpty(hotel.highlights)
  const hasCheckIn = isDefined(checkInTime) && !isEmpty(checkInTime)
  const hasCheckOut = isDefined(checkOutTime) && !isEmpty(checkOutTime)
  const hasPromotions = isDefined(hotelPromotions) && hotelPromotions.length > 0
  const hasCertificates =
    isDefined(hotel.certificates) && !isEmpty(hotel.certificates)
  const hasAwards = isDefined(hotel.awards) && !isEmpty(hotel.awards)
  const hasMorePromotionsThanMin =
    hotelPromotions.length > MAX_PROMOTIONS_UNTIL_SHOW_MORE
  const promotionsToShow =
    hasPromotions && hasToShowAllPromotions
      ? hotelPromotions
      : hotelPromotions.slice(0, MAX_PROMOTIONS_UNTIL_SHOW_MORE)

  return (
    <div>
      <Carousel
        className={styles.carousel}
        imageSlides={getHotelImages(hotel)}
        alt="Carrousel de imágenes del hotel"
        loader={getRoomImageLoader({
          aspectRatio: { width: 16, height: 9 },
        })}
        hasToShowFullScreenButton={false}
      />

      <div className={styles.details}>
        <div className={styles.hotelDetail}>
          {hasHighlightTag && (
            <Flex
              justifyContent="center"
              alignItems="center"
              gap="xxs"
              className={styles.highlightTag}
            >
              <Text
                fontStyle={{ mobile: 's-500', laptop: 'm-500' }}
                className={styles.serviceTitle}
              >
                {hotel.highlightTag}
              </Text>
            </Flex>
          )}
          <Flex direction="column" gap="xs">
            <Text fontStyle="l-500">
              {trans('aside-modal-tab_hotel-info_detail-title')}
            </Text>
            <Text fontStyle="m-300">
              {trans('aside-modal-tab_hotel-info_detail-subtitle')}
            </Text>
          </Flex>
          {hasHighlights && (
            <div className={styles.servicesContainer}>
              {hotel.highlights?.map((highlight, index) => (
                <Flex
                  key={index}
                  direction="column"
                  alignItems="center"
                  gap="xs"
                  className={styles.service}
                >
                  <Image
                    alt={highlight.title}
                    src={highlight.icon}
                    width={24}
                    height={24}
                    quality={75}
                  />
                  <Text
                    fontStyle="s-300"
                    className={styles.serviceTitle}
                    title={highlight.title}
                  >
                    {highlight.title}
                  </Text>
                </Flex>
              ))}
            </div>
          )}
          {hasCheckIn && hasCheckOut && (
            <Flex direction="row">
              <Flex direction="column" gap="xxs">
                <Text fontStyle="m-500">{trans('check-in_title')}</Text>
                <Text fontStyle="s-300">
                  {trans('check-in_from', { hour: checkInTime })}
                </Text>
              </Flex>
              <Divider dir="vertical" className={styles.divider} />
              <Flex direction="column" gap="xxs">
                <Text fontStyle="m-500">{trans('check-out_title')}</Text>
                <Text fontStyle="s-300">
                  {trans('check-out_until', { hour: checkOutTime })}
                </Text>
              </Flex>
            </Flex>
          )}
        </div>

        {hasPromotions && (
          <>
            <Divider dir="horizontal" className={styles.horizontalDivider} />
            <div className={styles.promotions}>
              <Text fontStyle="l-500">
                {trans(
                  'new-reservation:available-rooms_banner-advantages_title',
                )}
              </Text>
              {promotionsToShow.map((promotion, index) => (
                <Flex gap="xs" key={index}>
                  <Icon icon={SuccessIcon} size="s" className={styles.icon} />
                  <Text fontStyle="m-300">{promotion}</Text>
                </Flex>
              ))}
              {hasMorePromotionsThanMin && (
                <ButtonLink
                  className="touch-security-zone-always"
                  aria-label={trans(
                    hasToShowAllPromotions
                      ? 'drop-down_show-less'
                      : 'drop-down_show-more',
                  )}
                  onClick={event => {
                    event.preventDefault()
                    setHasToShowAllPromotions(!hasToShowAllPromotions)
                  }}
                >
                  <Flex>
                    <Text fontStyle="m-500">
                      {trans(
                        hasToShowAllPromotions
                          ? 'drop-down_show-less'
                          : 'drop-down_show-more',
                      )}
                    </Text>
                    <Icon
                      icon={ChevronIcon}
                      size="l"
                      color="button-secondary-enabled"
                      rotate={hasToShowAllPromotions ? 270 : 90}
                    />
                  </Flex>
                </ButtonLink>
              )}
            </div>
          </>
        )}

        {(hasCertificates || hasAwards) && (
          <>
            <Divider dir="horizontal" className={styles.horizontalDivider} />
            <AwardsAndCertificates
              awards={hotel.awards}
              certificates={hotel.certificates}
            />
          </>
        )}

        <Divider dir="horizontal" className={styles.horizontalDivider} />

        <>
          <Flex direction="column" gap="xs">
            <Text fontStyle="l-500">{trans('call-center_hero-title')}</Text>

            <Media mobile tablet>
              <Text fontStyle="m-300">
                {trans('call-center_hero-subtitle-mobile')}
              </Text>

              <Link
                link={`tel:${callCenterPhone}`}
                variant="simple"
                fontStyle="button"
                className={styles.callCenterPhone}
                data-data-phone={callCenterCountryCode}
                target={'_self'}
              >
                <IconText
                  icon={PhoneIcon}
                  iconSize="l"
                  iconColor="button-secondary-enabled"
                  text={`${callCenterPhone}${callCenterFreePhone}`}
                  fontStyle="m-700"
                  fontColor="primary"
                  gap="s"
                />
              </Link>
            </Media>

            <Media desktop laptop>
              <div>
                <Translate
                  i18nKey="common:call-center_hero-subtitle-desktop"
                  values={{
                    callCenterPhone: `${callCenterPhone}${callCenterFreePhone}`,
                  }}
                  components={{
                    regular: <Text fontStyle="m-300" />,
                    bold: (
                      <Text
                        data-data-phone={callCenterCountryCode}
                        fontStyle="m-700"
                      />
                    ),
                  }}
                />
              </div>
            </Media>
          </Flex>
        </>
      </div>
    </div>
  )
}
