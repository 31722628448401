import { FC } from 'react'
import styles from './ReviewsTab.module.scss'
import { DotRatings } from 'src/ui/views/_components/TripadvisorRatings/DotRatings'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Divider, Text } from 'src/ui/components'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import TripadvisorIcon from 'src/ui/components/atoms/Icon/_icons/social/TripadvisorIcon'
import { Review } from './Review'
import { Hotel } from 'src/core/Hotel/domain/Hotel.model'
import {
  isDefined,
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useTrans } from 'src/ui/hooks/useTrans'
import {
  getHotelTripadvisorRankLevel,
  TripadvisorRankLevel,
} from 'src/core/Hotel/domain/Hotel.model'

interface Props {
  hotel: Hotel
}

export const ReviewsTab: FC<Props> = ({ hotel: hotel }) => {
  const { trans } = useTrans(['common'])
  const ratingValue = hotel.review.averageRate!
  const reviewsCount = hotel.review.count
  const reviews = hotel.review.items
  const services = hotel.review.services

  const hasServices = isDefined(services) && !isEmpty(services)

  const rankLevelNames: Record<TripadvisorRankLevel, string> = {
    veryGood: trans('tripadvisor_average-name_level1'),
    excellent: trans('tripadvisor_average-name_level2'),
  }

  if (isUndefined(reviews)) {
    return null
  }

  return (
    <>
      <div className={styles.header}>
        <Flex
          gap="l"
          className={styles.headerContentWrapper}
          justifyContent="space-between"
        >
          <Flex alignItems="center" gap="m">
            <Icon size="3xl" color="icon-dark" icon={TripadvisorIcon} />
            <Text fontStyle="3xl-700">{ratingValue}</Text>
            <Flex direction="column">
              <Text fontStyle="s-700">
                {rankLevelNames[getHotelTripadvisorRankLevel(ratingValue)]}
              </Text>
              <Flex direction="row" alignItems="center" gap="xs">
                <DotRatings ratings={ratingValue} />
                <Text fontStyle="s-300">({reviewsCount})</Text>
              </Flex>
            </Flex>
          </Flex>

          {hasServices && (
            <div className={styles.ratingDetailsWrapper}>
              {services.map((service, index) => {
                return (
                  <Flex alignItems="center" key={`${service}-${index}`}>
                    <Text fontStyle="m-300" className="grow">
                      {service.name}
                    </Text>
                    <DotRatings
                      ratings={service.rate.toString()}
                      size="medium"
                    />
                  </Flex>
                )
              })}
            </div>
          )}
        </Flex>
      </div>
      <Flex direction="column" gap="l" className={styles.reviewsWrapper}>
        <Text fontStyle="l-500">{trans('tripadvisor_reviews_title')}</Text>
        {reviews.map((review, index) => (
          <div key={review.id}>
            {index > 0 && <Divider dir="horizontal" className="mb-m" />}
            <Review review={review}></Review>
          </div>
        ))}
      </Flex>
    </>
  )
}
