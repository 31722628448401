import { FC } from 'react'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Text } from 'src/ui/components'
import { ReviewItem } from 'src/core/Hotel/domain/Hotel.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { DotRatings } from 'src/ui/views/_components/TripadvisorRatings/DotRatings'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'

interface Props {
  review: ReviewItem
}

export const Review: FC<Props> = ({ review: review }) => {
  return (
    <>
      <Text fontStyle="m-300" className="mb-xxs">
        {review.username}
      </Text>
      <Flex gap="s" alignItems="center" className="mb-xxs">
        {isDefined(review.rating) && (
          <DotRatings ratings={review.rating.toString()} size="medium" />
        )}
        <Text fontStyle="s-300">
          {datesManager.formatDateToLocale(review.publishedDate, 'short')}
        </Text>
      </Flex>
      <Flex gap="xs" direction="column" className="mt-m">
        <Text fontStyle="m-700">{review.title}</Text>
        <Text fontStyle="m-300">{review.text}</Text>
      </Flex>
    </>
  )
}
