import { useQueryService } from 'src/ui/hooks/useQuery'
import {
  isDefined,
  isNotEmptyString,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { container } from 'src/core/Shared/_di'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'

export const useHotelPromotions = () => {
  const { queryUtils } = useApplicationRouter()
  const hotelId = queryUtils.getRawParam('hotel')
  const checkIn = queryUtils.getRawParam('arrive')
  const checkOut = queryUtils.getRawParam('depart')

  const { data: hotelPromotions, isValidating } = useQueryService(
    'hotel-promotions',
    isDefined(hotelId) && isDefined(checkIn) && isDefined(checkOut)
      ? [hotelId, checkIn, checkOut]
      : null,
    () =>
      container.resolve('getHotelPromotions')(hotelId!, checkIn!, checkOut!),
  )

  const hotelPromotionsList = hotelPromotions?.reduce<string[]>(
    (acc, promotion) => {
      acc.push(...promotion.description.split('- ').filter(isNotEmptyString))
      return acc
    },
    [],
  )

  return {
    hotelPromotions: hotelPromotionsList ?? [],
    isLoadingPromotions: isValidating,
  }
}
