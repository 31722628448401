import { FC } from 'react'
import { classNames } from 'src/ui/utils/classnames'
import styles from './TagButton.module.scss'

interface Props {
  isSelected: boolean
  label: string
  className?: string
  onClick: () => void
}

export const TagButton: FC<Props> = ({
  isSelected,
  className,
  label,
  onClick,
}) => {
  return (
    <button
      type="button"
      className={classNames(
        styles.tagButton,
        isSelected && styles.isSelected,
        className,
      )}
      onClick={onClick}
      aria-pressed={isSelected}
    >
      {label}
    </button>
  )
}
