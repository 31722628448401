import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { A11y, Keyboard, Virtual } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperClass } from 'swiper/types'
import styles from './CarouselMobile.module.scss'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Image } from 'src/ui/components/atoms/Image'
import { Text } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import {
  NavigationNextButton,
  NavigationPreviousButton,
} from '../NavigationButtons'
import { classNames } from 'src/ui/utils/classnames'
import { AwardsAndCertificates } from 'src/core/Hotel/domain/Hotel.model'

interface Props {
  item: AwardsAndCertificates[]
  alt: string
  isRTL: boolean
  hasToShowNavigationArrows: boolean
}

export const CarouselMobile: FC<Props> = ({
  item,
  alt,
  isRTL,
  hasToShowNavigationArrows,
}) => {
  const { trans } = useTrans(['common'])
  const swiperRef = useRef<SwiperClass>()
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)

  const handleSlideChange = useCallback((swiper: SwiperClass) => {
    setIsBeginning(swiper.isBeginning)
    setIsEnd(swiper.isEnd)
  }, [])

  useEffect(() => {
    if (isUndefined(swiperRef.current)) {
      return
    }
    const swiper = swiperRef.current
    swiper.slideTo(0, 0)
    handleSlideChange(swiper)
  }, [swiperRef, item, handleSlideChange])

  return (
    <Flex
      direction="column"
      alignItems="stretch"
      gap="l"
      className={styles.wrapper}
    >
      <Swiper
        a11y={{
          enabled: true,
          prevSlideMessage: trans('swiper_previous-slide'),
          nextSlideMessage: trans('swiper_next-slide'),
          firstSlideMessage: trans('swiper_first-slide'),
          lastSlideMessage: trans('swiper_last-slide'),
        }}
        aria-label={trans('swiper_carousel-heading', { alt })}
        className={styles.swiperContainer}
        initialSlide={0}
        keyboard={{
          enabled: true,
        }}
        modules={[A11y, Keyboard, Virtual]}
        onSwiper={swiper => {
          if (isUndefined(swiperRef)) return

          swiperRef.current = swiper
          handleSlideChange(swiper)
        }}
        onSlideChange={swiper => handleSlideChange(swiper)}
        slidesPerView={1}
        tag="section"
        virtual={true}
        wrapperTag="ul"
      >
        {item.map((imageSlide, index) => {
          return (
            <SwiperSlide key={index} tabIndex={-1} tag="li">
              {({ isVisible }) => (
                <Flex
                  direction="column"
                  gap="4xl"
                  className={classNames(
                    !hasToShowNavigationArrows &&
                      styles.swiperSlideWithoutNavigationArrows,
                  )}
                >
                  <div className={styles.imageWrapper}>
                    <Image
                      className={styles.image}
                      alt={imageSlide.title ?? imageSlide.description}
                      aria-hidden={!isVisible}
                      src={imageSlide.logoPath}
                      height={260}
                      width={254}
                      quality={75}
                      priority={index === 0}
                    />
                  </div>
                  <Text fontStyle="m-300" className={styles.descriptionText}>
                    {imageSlide.description}
                  </Text>
                </Flex>
              )}
            </SwiperSlide>
          )
        })}
      </Swiper>

      {hasToShowNavigationArrows && isDefined(swiperRef.current) && (
        <div className={styles.arrowContainer}>
          <NavigationPreviousButton
            swiperRef={swiperRef.current}
            isRTL={isRTL}
            isBeginning={isBeginning}
          />
          <NavigationNextButton
            swiperRef={swiperRef.current}
            isRTL={isRTL}
            isEnd={isEnd}
          />
        </div>
      )}
    </Flex>
  )
}
