import { FC } from 'react'

interface Props {
  color: string
}

export const MapMaker: FC<Props> = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 64 64" fill="none">
    <g clipPath="url(#clip0_766_100030)">
      <path
        d="M23.9537 55.9998C47.9843 50.4294 59.9997 46.1051 59.9997 43.0269C59.9997 38.4096 50.4484 34.6665 38.6663 34.6665C26.8843 34.6665 17.333 38.4096 17.333 43.0269C17.333 46.1051 19.5399 50.4294 23.9537 55.9998Z"
        fill="url(#paint0_linear_766_100030)"
      />
      <path
        d="M24.0003 55.9998C38.2225 42.0737 45.3337 31.2629 45.3337 23.5674C45.3337 12.0242 35.7824 2.6665 24.0003 2.6665C12.2183 2.6665 2.66699 12.0242 2.66699 23.5674C2.66699 31.2629 9.7781 42.0737 24.0003 55.9998Z"
        fill="#384044"
      />
      <ellipse
        cx="24"
        cy="56.6667"
        rx="8"
        ry="4.66667"
        fill="url(#paint1_radial_766_100030)"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.6579 20.9578C29.2846 20.5109 30.3305 18.9801 30.1193 17.355C30.1193 14.9968 28.848 13.8046 26.1432 13.5295C25.5346 13.4247 24.358 13.333 23.6277 13.333H17.9882C17.5013 13.333 17.339 13.5688 17.339 13.9225V15.2326C17.3155 15.4008 17.3754 15.5698 17.5006 15.6885C17.6258 15.8072 17.8016 15.8617 17.9746 15.8353H18.8131V26.8139H17.9882C17.5013 26.8139 17.339 27.0497 17.339 27.4034V28.7135C17.3155 28.8817 17.3754 29.0507 17.5006 29.1694C17.6258 29.2881 17.8016 29.3425 17.9746 29.3161H23.4925C25.0972 29.3906 26.704 29.2181 28.253 28.8052C29.8965 28.1 30.8651 26.4313 30.6332 24.7046C30.8504 22.8818 29.5324 21.2219 27.6579 20.9578ZM26.873 17.8791C26.935 18.6531 26.4688 19.3757 25.7235 19.6609C25.1007 19.8371 24.4494 19.8993 23.8031 19.8443H22.0449V15.8485H23.6137C24.2332 15.832 24.8521 15.8982 25.453 16.045C26.3475 16.211 26.9455 17.0336 26.8054 17.9053L26.873 17.8791ZM26.0481 26.5648C26.7919 26.3028 27.2382 25.6608 27.2382 24.4555H27.3058C27.39 23.6341 26.8807 22.8643 26.0751 22.5952C25.3806 22.4066 24.6577 22.3357 23.9383 22.3856H22.0449V26.8137H23.6543C24.4606 26.8465 25.2674 26.7626 26.0481 26.5648Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_766_100030"
        x1="38.6663"
        y1="34.6665"
        x2="38.6663"
        y2="55.9998"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#001119" stopOpacity="0.1" />
        <stop offset="1" stopColor="#001119" stopOpacity="0.3" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_766_100030"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24 56.6667) scale(8 1.83563)"
      >
        <stop stopColor="#001119" stopOpacity="0.3" />
        <stop offset="1" stopColor="#001119" stopOpacity="0.01" />
      </radialGradient>
      <clipPath id="clip0_766_100030">
        <rect
          width="58.6667"
          height="58.6667"
          fill="white"
          transform="translate(2.66699 2.6665)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default MapMaker
