import { createModal } from 'src/ui/hooks/useModal'
import { AsideModal, Tabs } from 'src/ui/components'
import { BLoader } from 'src/ui/components/atoms/BLoader/BLoader'
import { hasToShowReviews } from 'src/core/Hotel/domain/Hotel.model'
import { ReviewsTab } from 'src/ui/views/_components/HotelDetailModal/ReviewsTab'
import { InformationTab } from './InformationTab'
import { LocationTab } from './LocationTab'
import { useTrans } from 'src/ui/hooks/useTrans'
import { useHotel } from 'src/ui/hooks/queries/useHotel'
import styles from './HotelDetailModal.module.scss'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

interface Props {
  hotelId: string
}

export const HotelDetailModal = createModal(({ hotelId }: Props) => {
  const { trans } = useTrans(['common'])
  const hotel = useHotel({ id: hotelId })

  if (isUndefined(hotel)) {
    return (
      <AsideModal title="">
        <div className={styles.loaderWrapper}>
          <BLoader />
        </div>
      </AsideModal>
    )
  }

  const tabs = [
    {
      title: trans('aside-modal-tab_hotel-info-title'),
      container: <InformationTab hotel={hotel} />,
    },
    {
      title: trans('aside-modal-tab_location-title'),
      container: <LocationTab hotel={hotel} />,
    },
  ]

  if (hasToShowReviews(hotel)) {
    tabs.push({
      title: trans('aside-modal-tab_reviews-title'),
      container: <ReviewsTab hotel={hotel} />,
    })
  }

  return (
    <AsideModal title={hotel.name}>
      <Tabs tabs={tabs} />
    </AsideModal>
  )
})
