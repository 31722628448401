import { FC } from 'react'
import { SwiperClass } from 'swiper/react'
import { Icon } from 'src/ui/components/atoms/Icon'
import ArrowIcon from 'src/ui/components/atoms/Icon/_icons/navigation/ArrowIcon'
import styles from './NavigationButtons.module.scss'
import { classNames } from 'src/ui/utils/classnames'

interface Props {
  swiperRef: SwiperClass
  isRTL: boolean
  isEnd: boolean
}

export const NavigationNextButton: FC<Props> = ({
  swiperRef,
  isRTL,
  isEnd,
}) => {
  return (
    <div
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        swiperRef.slideNext()
      }}
      className={classNames(styles.navigation, isEnd && styles.disableArrow)}
    >
      <Icon
        size="xl"
        icon={ArrowIcon}
        color={
          isEnd ? 'interactive-primary-disabled' : 'interactive-primary-enabled'
        }
        rotate={isRTL ? 0 : -180}
      />
    </div>
  )
}
